import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import SplashScreen from '../components/pages/splash';

import '../styles/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const Index = ({ defaultVisible = true }) => {
  const [visible, setVisible] = useState(defaultVisible);
  useEffect(() => {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
      navigate('/app/specs/materials');
    }, 7000);
  }, []);

  return <div data-test="index-component">{visible ? <SplashScreen /> : <></>}</div>;
};

export default Index;
