import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const SplashScreen = () => (
  <div data-test="splash-component" className="splash-screen">
    <div className="splash-wrap">
      <StaticImage
        src="../../assets/images/endurica-logo.jpg"
        placeholder="blurred"
        className="mx-auto my-2"
        alt="Endurica Companion"
        height={60}
        layout="fixed"
      />
      <StaticImage
        src="../../assets/images/logo.jpg"
        placeholder="blurred"
        className="mx-auto my-2"
        alt="Endurica Companion"
        height={60}
        layout="fixed"
      />
      <div className="gooey">
        <span className="dot" />
        <div className="dots">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  </div>
);

export default SplashScreen;
